import React from 'react';
import toastr from 'toastr';

import Layout from '../../components/Layout/Layout';
import CookiesPolicy from '../../components/Landing-info/CookiesPolicy/CookiesPolicy';

import styles from './index.module.scss';

const Index = () => {
    const handleSubmit = () => {
        toastr.success('Changes accepted');
    }
    return (
        <Layout mainClasses="section-padded-wrapper flex-grow-1">
            <div className={styles.container}>
                <CookiesPolicy showClose={false} onSubmit={handleSubmit} show={true} />
            </div>
        </Layout>
    );
};

export default Index;